<template>
  <div class="page pos-r overflow-h center">
    <div class="logo"></div>
    <div class="login-wrap center">
      <div class="mt-27" v-if="lang() == 'zh'">
        <input
          type="text"
          :placeholder="$t('reg.username')"
          v-model="params.username"
        />
      </div>

      <div class="mt-27" v-else>
        <el-row :gutter="20">
          <el-col :span="12">
            <input type="text" placeholder="姓" v-model="params.firstName" />
          </el-col>
          <el-col :span="12">
            <input type="text" placeholder="名" v-model="params.lastName" />
          </el-col>
        </el-row>
      </div>

      <div class="mt-27">
        <input
          type="email"
          :placeholder="$t('reg.email')"
          v-model="params.email"
        />
      </div>
      <div class="mt-27">
        <input
          v-model="params.password"
          type="password"
          :placeholder="$t('reg.password')"
        />
      </div>
      <div class="mt-27">
        <input
          v-model="params.rpassword"
          type="password"
          :placeholder="$t('reg.rpassword')"
        />
      </div>
      <div class="mt-27">
        <input
          type="tel"
          v-model="params.phoneNumber"
          :placeholder="$t('reg.phoneNumber')"
        />
      </div>
      <div class="mt-27" v-if="lang() == 'zh'">
        <el-row :gutter="20">
          <el-col :span="17">
            <input
              type="text"
              v-model="params.verificationCode"
              :placeholder="$t('reg.verificationCode')"
          /></el-col>
          <el-col :span="7">
            <div class="yzm" @click="mc">{{ text }}</div>
          </el-col>
        </el-row>
      </div>
      <div class="mt-27">
        <el-row :gutter="20">
          <el-col :span="17">
            <input
              type="text"
              v-model="params.company"
              :placeholder="$t('reg.company')"
            />
          </el-col>
          <el-col :span="7">
            <el-checkbox class="mt-10" @change="handleChange" v-model="checked"
              >{{ $t("reg.wsabb") }}
            </el-checkbox>
          </el-col>
        </el-row>
      </div>

      <div class="mt-27">
        <input
          type="text"
          :placeholder="$t('reg.position')"
          v-model="params.position"
        />
      </div>
      <div class="mt-27" v-if="!checked">
        <el-select v-model="params.industry" :placeholder="$t('reg.industry')">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="flex mt-34">
        <div class="flex-1 ml-10">
          <el-checkbox v-model="read">
            <p class="c-b2b0b0">
              {{ $t("reg.yudu") }}
              <router-link
                :to="{
                  path: '/xieyi',
                  query: { lang: lang() },
                }"
                class="c-red"
              >
                {{ $t("reg.zc") }}
              </router-link>
            </p>
          </el-checkbox>
        </div>
      </div>

      <div class="mt-40 flex flex-b">
        <div class="btn-reg" @click="reg">{{ $t("reg.reg") }}</div>
        <div class="text-c c-b2b0b0 f-21 mt-17">
          <p>
            {{ $t("reg.yy") }}
            <router-link
              class="c-blue"
              :to="{ path: '/login', query: { lang: lang() } }"
              >{{ $t("reg.ljdl") }}
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import langMinix from "../common/lang";
import { reg, mc, cfg } from "../api/index.js";
import { lang } from "../utils/utils";

export default {
  name: "Reg",
  components: {},
  data: () => {
    return {
      checked: false,
      read: true,
      status: 0,
      lang: lang,
      options: [],

      params: {
        firstName: "",
        lastName: "",
        verificationCode: "",
        username: "",
        password: "",
        rpassword: "",
        email: "",
        phoneNumber: "",
        company: "", //公司
        industry: "", //行业
        position: "", //职位
        nickName: "", //昵称
      },
      timer: null,
      waitTime: 60,
      text: "发送验证码",
    };
  },
  created() {},
  mounted() {
    this.cfg();
  },
  minixs: [langMinix],
  methods: {
    changeStatus(status) {
      console.log(status);
      this.status = status;
    },

    handleChange(e) {
      if (e) {
        this.params.company = "ABB";
      } else {
        this.params.company = "";
      }
    },
    async reg() {
      try {
        console.log(this.username, this.password);
        this.params.nickName = this.params.username;
        if (
          !/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
            this.params.email
          )
        ) {
          this.$message("邮箱格式不正确");
          return;
        }

        if (this.params.phoneNumber.length != 11) {
          this.$message("请正确输入手机号");
          return;
        }

        if (!this.read) {
          this.$message("请勾选阅读协议");
          return;
        }

        if (this.params.password == "") {
          this.$message("密码不能为空");
          return;
        }

        if (this.params.rpassword != this.params.password) {
          this.$message("两次密码不一致");
          return;
        }

        let res = await reg(this.params);
        if (res.code == 0) {
          this.$router.replace("/login");
        } else if (res.code == "-1") {
          this.$message(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },

    async mc() {
      try {
        if (this.waitTime > 0 && this.waitTime != "60") {
          this.$message("短信已经发送1分钟内有效");
          return;
        }
        let res = await mc(this.params.phoneNumber);
        if (res.code == 0) {
          this.daojishi();
        } else {
          this.$message(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },

    async cfg() {
      try {
        let res = await cfg();
        console.log("cfg-->", res);
        if (res.code == "0" && res?.data?.industry?.values) {
          let arr = res.data.industry.values;
          arr.forEach((item) => {
            this.options.push({
              value: item.value,
              label: item.key,
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    daojishi() {
      this.timer = setInterval(() => {
        this.waitTime--;
        if (this.waitTime == "0") {
          clearInterval(this.timer);
          this.timer = null;
          this.text = "发送验证码";
          this.waitTime = 60;
        } else {
          this.text = `倒计时${this.waitTime}s`;
        }
      }, 1000);
    },
  },
  unmounted() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>
<style lang="less" scoped>
.page {
  width: 1920px;
}

.logo {
  width: 211px;
  height: 83px;
  background: url("../assets/img/logo.jpg");
  position: absolute;
  top: 50px;
  right: 50px;
}

.login-wrap {
  width: 600px;
  margin-top: 120px;

  .yzm {
    width: 158px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    background: #ff0000;
    border-radius: 23px;
    color: #fff;
    font-size: 20px;
  }

  /deep/ .el-input {
    width: 100%;
    height: 45px;
    border: 1px solid #a4a4a4;
    border-radius: 23px;
    padding: 5px 25px;
  }

  /deep/ .el-input__inner {
    border: none;
    background-color: none;
    padding: 0;
    height: 38px;
    line-height: 38px;
    width: 550px;
  }

  .mt-27 {
    margin-top: 17px;
  }

  .mt-40 {
    margin-top: 40px;
  }

  input {
    width: 100%;
    height: 45px;
    border: 1px solid #a4a4a4;
    border-radius: 23px;
    padding: 5px 25px;
  }

  .checkbox {
    width: 34px;
    height: 34px;
    border: 1px solid #8c8c8c;
    margin-top: 20px;
  }

  .btn-reg {
    width: 310px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    background-color: var(--login-red);
    color: #fff;
    border-radius: 30px;
    font-size: 30px;
    cursor: pointer;
  }
}

.c-b2b0b0 {
  color: #b2b0b0;
}

@media screen and (max-width: 1680px) {
  .page {
    width: 100vw;
  }
}

@media screen and (max-width: 1200px) {
  .page {
    width: 1200px;
  }
}
</style>
